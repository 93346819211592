#toc .titleChevronLink {
  max-height: 22px;
  color: #AAA;
}

#toc .titleChevronLink:hover {
  color: #333;
}

.schemeIcons {
  flex: 0 0 auto;
  white-space: nowrap;
  max-height: 22px;
}
.schemeIcon {
  height: 20px;
  width: 20px;
  margin-left: 4px;
}
.schemeIcon:first-child {
  margin-left: 8px;
}